const initialState = {
    agents: [],
    total: 1,
    params: {},
    selectedAgent: null
}

const agents = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_AGENTS':
            return {
                ...state,
                agents: action.data.items,
                total: action.data.size
            }
        case 'GET_AGENT':
            return {...state, selectedAgent: action.selectedAgent}
        case 'ADD_AGENT':
            return {...state}
        case 'EDIT_AGENT':
            return {...state}
        case 'DELETE_AGENT':
            return {...state}
        default:
            return {...state}
    }
}
export default agents
