const initialState = {
    references: [],
    total: 1,
    params: {},
    selectedReferences: null,
}

const references = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_REFERENCES':
            return {
                ...state,
                references: action.data.items,
            }
        case 'GET_REFERENCE':
            return {
                ...state,
                selectedReferences: action.data.items,
            }
        case 'CREATE_REFERENCE':
            return {...state}
        case 'DELETE_REFERENCE':
            return {...state}
        case 'UPDATE_REFERENCE':
            return {...state}
        default:
            return {...state}
    }
}
export default references
