const initialState = {
    services: [],
    total: 1,
    params: {},
    selectedService: null,
}

const references = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_SERVICES':
            return {
                ...state,
                services: action.data.items,
            }
        case 'GET_SERVICE':
            return {
                ...state,
                selectedService: action.selectedService,
            }
        case 'ADD_SERVICE':
            return {...state}
        case 'DELETE_SERVICE':
            return {...state}
        case 'UPDATE_SERVICE':
            return {...state}
        default:
            return {...state}
    }
}
export default references
