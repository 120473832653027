const initialState = {
    freight: [],
    total: 1,
    params: {},
    selectedFreight: null,
}

const agents = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_FREIGHTS':
            return {
                ...state,
                freight: action.data.items,
                total: action.data.size
            }
        case 'GET_FREIGHT':
            return {
                ...state,
                selectedFreight: action.selectedFreight
            }
        case 'EDIT_FREIGHT':
            return {...state}
        default:
            return {...state}
    }
}
export default agents
