import {combineReducers} from 'redux'

/* Reducers */
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import accountSettings from '@src/views/apps/accountSettings/store/reducer'
import references from '@src/views/apps/reference/store/reducer'
import teams from '@src/views/apps/team/store/reducer'
import agents from '@src/views/others/agent/store/reducer'
import agentUsers from '@src/views/others/agentUser/store/reducer'
import freightCalculationList from '@src/views/others/freightCalculationList/store/reducer'
import priceOffers from '@src/views/others/priceOffers/store/reducer'
import expressPriceList from '@src/views/others/expressPriceList/store/reducer'
import contacts from '@src/views/apps/contact/store/reducer'
import services from '@src/views/apps/service/store/reducer'
import chat from '@src/views/apps/chat/store/reducer'
import todo from '@src/views/apps/todo/store/reducer'
import users from '@src/views/apps/user/store/reducer'
import pages from '@src/views/apps/page/store/reducer'
import banners from '@src/views/apps/banner/store/reducer'
import email from '@src/views/apps/email/store/reducer'
import invoice from '@src/views/apps/invoice/store/reducer'
import calendar from '@src/views/apps/calendar/store/reducer'
import ecommerce from '@src/views/apps/ecommerce/store/reducer'
import dataTables from '@src/views/tables/data-tables/store/reducer'
import blogs from '@src/views/apps/blog/store/reducer'
import myCargos from '@src/views/others/myCargos/store/reducer'

const rootReducer = combineReducers({
    auth,
    todo,
    chat,
    email,
    users,
    navbar,
    layout,
    invoice,
    calendar,
    ecommerce,
    dataTables,
    banners,
    accountSettings,
    references,
    teams,
    pages,
    contacts,
    agents,
    agentUsers,
    freightCalculationList,
    priceOffers,
    services,
    expressPriceList,
    blogs,
    myCargos,
})

export default rootReducer
