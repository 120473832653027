import {useState, createContext, useEffect} from 'react'
import {IntlProvider, createIntlCache} from 'react-intl'

/* Core Data */
import messagesEn from '@assets/data/locales/en.json'
import messagesDe from '@assets/data/locales/de.json'
import messagesFr from '@assets/data/locales/fr.json'
import messagesPt from '@assets/data/locales/pt.json'
import messagesTr from '@assets/data/locales/tr.json'

/* User Data */
import userMessagesEn from '@src/assets/data/locales/en.json'
import userMessagesDe from '@src/assets/data/locales/de.json'
import userMessagesFr from '@src/assets/data/locales/fr.json'
import userMessagesPt from '@src/assets/data/locales/pt.json'
import userMessagesTr from '@src/assets/data/locales/tr.json'

const menuMessages = {
    en: {...messagesEn, ...userMessagesEn},
    de: {...messagesDe, ...userMessagesDe},
    fr: {...messagesFr, ...userMessagesFr},
    pt: {...messagesPt, ...userMessagesPt},
    tr: {...messagesTr, ...userMessagesTr}
}

const Context = createContext()
const cache = createIntlCache()

const IntlProviderWrapper = ({children}) => {
    const [locale, setLocale] = useState('tr')
    const [messages, setMessages] = useState(menuMessages['tr'])
    const [lang, setLang] = useState(['tr', 'en'])

    useEffect(() => {
        window.lang = locale
    }, [locale])
    const switchLanguage = lang => {
        setLocale(lang)
        setMessages(menuMessages[lang]);
    }

    return (
        <Context.Provider value={{locale, lang, switchLanguage}}>
            <IntlProvider key={locale} locale={locale} messages={messages} defaultLocale='en'>
                {children}
            </IntlProvider>
        </Context.Provider>
    )
}

export {IntlProviderWrapper, Context as IntlContext}
