const initialState = {
    pages: [],
    total: 1,
    params: {},
    selectedPage: null
}

const teams = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_PAGES':
            return {
                ...state,
                pages: action.data.items,
                total: action.data.size
            }
        case 'GET_PAGE':
            return {...state, selectedPage: action.selectedPage}
        case 'ADD_PAGE':
            return {...state}
        case 'EDIT_PAGE':
            return {...state}
        case 'DELETE_PAGE':
            return {...state}
        default:
            return {...state}
    }
}
export default teams
