const initialState = {
    allData: [],
    data: [],
    total: 1,
    params: {},
    selectedUser: null
}

const users = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ALL_DATA':
            return {
                ...state,
                allData: action.data.items,
                total: action.data.size
            }
        case 'GET_DATA':
            return {
                ...state,
                data: action.data.items,
                total: action.data.size,
                params: action.data.params
            }
        case 'GET_USER':
            return {...state, selectedUser: action.selectedUser}
        case 'ADD_USER':
            return {...state}
        case 'EDIT_USER':
            return {...state}
        case 'DELETE_USER':
            return {...state}
        default:
            return {...state}
    }
}
export default users
