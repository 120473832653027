const initialState = {
    shipments: [],
    total: 1,
}

const myCargos = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_SHIPMENTS':
            return {
                ...state,
                shipments: action.data.items,
                total: action.data.size
            }
        case 'GET_SHIPMENT':
            return {...state, selectedShipment: action.selectedShipment}
        default:
            return {...state}
    }
}
export default myCargos
