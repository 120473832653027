const initialState = {
    blogs: [],
    total: 1,
    selectedBlog: null,
}

const blogs = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_BLOGS':
            return {
                ...state,
                blogs: action.data.items,
                total: action.data.size
            }
        case 'GET_BLOG':
            return {
                ...state,
                selectedBlog: action.selectedBlog,
            }
        case 'ADD_BLOG':
            return {...state}
        case 'DELETE_BLOG':
            return {...state}
        case 'UPDATE_BLOG':
            return {...state}
        default:
            return {...state}
    }
}
export default blogs
