const initialState = {
    teams: [],
    total: 1,
    params: {},
    selectedTeam: null
}

const teams = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_TEAMS':
            return {
                ...state,
                teams: action.data.items,
                total: action.data.size
            }
        case 'GET_TEAM':
            return {...state, selectedTeam: action.selectedTeam}
        case 'ADD_TEAM':
            return {...state}
        case 'EDIT_TEAM':
            return {...state}
        case 'DELETE_TEAM':
            return {...state}
        default:
            return {...state}
    }
}
export default teams
