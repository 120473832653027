const initialState = {
    countries: [],
    prices: [],
}

const agents = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_REGIONS_COUNTRIES':
            return {
                ...state,
                countries: action.data.items,
            }
        case 'GET_REGIONS_PRICE':
            return {
                ...state,
                prices: action.data.items,
            }
        default:
            return {...state}
    }
}
export default agents
