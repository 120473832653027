const initialState = {
    priceOffers: [],
    total: 1,
    params: {},
    selectedPriceOffer: null
}

const agents = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_PRICE_OFFERS':
            return {
                ...state,
                priceOffers: action.data.items,
                total: action.data.size
            }
        case 'GET_PRICE_OFFER':
            return {...state, selectedPriceOffer: action.selectedPriceOffer}
        default:
            return {...state}
    }
}
export default agents
