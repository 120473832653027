const initialState = {
    userProfile: null,
}

const accountSettings = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_PROFILE':
            return {
                ...state,
                userProfile: action.data.items,
            }
        case 'UPDATE_PROFILE':
            return {...state}
        default:
            return {...state}
    }
}
export default accountSettings
