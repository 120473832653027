const initialState = {
  contacts: [],
  total: 1,
  params: {},
  selectedContact: null,
  contactCounts: {},
}

const users = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_CONTACTS':
      return {
        ...state,
        contacts: action.data.items,
        total: action.data.size
      }
    case 'GET_CONTACT':
      return {
        ...state,
       selectedContact: action.selectedContact
      }
    case 'EDIT_CONTACT':
      return {...state}
    case 'GET_CONTACT_COUNTS':
      return {...state, contactCounts: action.contactCounts}
    default:
      return {...state}
  }
}
export default users
