const initialState = {
    banners: [],
    total: 1,
    params: {},
    selectedBanner: null,
}

const banners = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_BANNERS':
            return {
                ...state,
                banners: action.data.items,
                total: action.data.size
            }
        case 'GET_BANNER':
            return {
                ...state,
                selectedBanner: action.selectedBanner
            }
        case 'ADD_BANNER':
            return {...state}
        case 'EDIT_BANNER':
            return {...state}
        case 'DELETE_BANNER':
            return {...state}
        default:
            return {...state}
    }
}
export default banners
