const initialState = {
    agentUsers: [],
    total: 1,
    params: {},
}

const agents = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_AGENT_USERS':
            return {
                ...state,
                agentUsers: action.data.items,
                total: action.data.size
            }
        case 'ADD_AGENT_USERS':
            return {...state}
        case 'DELETE_AGENT_USER':
            return {...state}
        default:
            return {...state}
    }
}
export default agents
